.postf{

    position: relative;
    margin: -90px 60px 0;
    padding: 40px;
    z-index: 2;

}

body{

  background-color: #1b191b;

}

.postf .col-md-8{

  padding:40px;
  box-shadow: 0px 2px 9px -8px #000;

}




.fileToDownload{
  box-shadow: 0px 2px 9px -8px #000;
  background-color: #fff;
  padding:30px;

}

.post-info{ padding: 14px !important;     margin-top: -39px; }

.post-content-holder{

  margin-top: 0px;
  z-index: 0;
  margin-top: 50px;

}

.post {

  height: 460px !important;
  
}

.post-image{

  height: 160px;
  padding-left: ;
  padding-right: ;

}

.post-image img{

    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: 190px;

    padding: 25px; 

}

.fontFundo{ color: #fff; }

.divarticle{ 
  
  box-shadow: 0px 4px 8px -8px #000;
  background-color: #fff;
  border-radius: 5px;
  

 }

.post-content{ border-radius: 5px; }
.post-content-holder{  padding-left: 0px; }

.post:hover #ida1{ display: inherit; }

#ida1{

  position: absolute;
  left:calc(100% - 35px);
  display: none;
  top: 15px;

}

#formPost{ background-color: #fff; padding:100px; }

.inputSeach{

    width: 500px;
    border-radius: 15px;
    border: none !important;

}

@media (max-height: 900px){

  .padded{

    padding: 10px 30px 10px 60px; 

  }

  .top-bar{

    padding-bottom: 40px;
    margin-bottom: 4px;

  }

  .btn.btn-pull-up{

    margin-top: 0px;
    padding:5px 20px;

  }

  .short{ text-align: center; font-size: 40px; }

  .logo-content .short{

    font-size: 28px; 

  }


  .pull-right h6{

    margin-top: 10px;

  }

  .content.padded{ padding-top: 10px !important; }

  .profile-content h1{ text-align: left; }

  ul.social-icons.si-pull-up{

    margin-top: 0px;
    
  }

  ul.social-icons li a{

    height: 28px;

  }

  ul.social-icons li a i{

    margin-top: 6px;

  }

  .inputSeach{ margin-top:6px; }

}